import queryString from 'query-string';

const Hr = {
  async loadAllEmployees(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/employee?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async searchEmployee(baseApiUrl, searchFields, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        search: true,
      };

      for(let i = 0; i < Object.keys(searchFields).length; i++){
        const key = Object.keys(searchFields)[i];
        const value = searchFields[key];
        queryList[key] = value;
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/employee?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  /* Load Emplyoee by Id. */
  async loadEmployee(baseApiUrl, employeeId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/employee/${employeeId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async createEmployee(baseApiUrl, employeeInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        employeeInfo: employeeInfo,
      };

      const apiUrl = `${baseApiUrl}/employee`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create employee information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async updateEmployee(baseApiUrl, employeeId, employeeInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        employeeInfo: employeeInfo,
      };

      const apiUrl = `${baseApiUrl}/employee/${employeeId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update employee information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteEmployee(baseApiUrl, employeeId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/employee/${employeeId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete employee information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadEmployeeGroups(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/group?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee group information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadEmployeeGroupType(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/groupType?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee group type. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadEmployeeGroup(baseApiUrl, groupId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/group/${groupId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee group information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createEmployeeGroup(baseApiUrl, groupInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        groupInfo: {
          'name': groupInfo['name'],
          'type': groupInfo['groupType'],
          'members': groupInfo['groupMembers'],
        }
      };

      const apiUrl = `${baseApiUrl}/group`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create employee group. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async updateEmployeeGroup(baseApiUrl, groupId, groupInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        groupInfo: {
          'name': groupInfo['name'],
          'type': groupInfo['groupType'],
          'members': groupInfo['groupMembers'],
        }
      };

      const apiUrl = `${baseApiUrl}/group/${groupId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update employee group information. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteEmployeeGroup(baseApiUrl, groupId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/group/${groupId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete employee group. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadAllAttendance(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };
      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/attendance?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading employee information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }
      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadUserAttendance(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'], //For checking login
        token: loginInfo['token'],
        user_id: loginInfo['userId'],
        search: true,
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/attendance?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading attendance information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async clockIn(baseApiUrl, recordId, workshiftId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        workshiftId: workshiftId,
        status: 'clock_in',
      };

      const apiUrl = `${baseApiUrl}/attendance/${recordId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update attendance status. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async clockOut(baseApiUrl, recordId, workshiftId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        workshiftId: workshiftId,
        status: 'clock_out',
      };

      const apiUrl = `${baseApiUrl}/attendance/${recordId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update attendance status. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadAllAttendanceApplications(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'attendance',
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/application?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading application information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadAttendanceApplication(baseApiUrl, applicationId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'attendance',
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/application/${applicationId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading application information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createAttendanceApplication(baseApiUrl, applicationInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
       const postValues = {
         userId: loginInfo['userId'],
         token: loginInfo['token'],
         type: 'attendance',
         applicationInfo,
       };

       const apiUrl = `${baseApiUrl}/application`;
       const fetchResult = await fetch(apiUrl, {
         method: 'POST',
         headers: {
          'Content-type': 'application/json; charset=UTF-8'
         },
         body: JSON.stringify(postValues),
       });

       const status = await fetchResult.status;

       //If cannot connect with API server
       if(status !== 200 && status !== 401){
         const err = 'Cannot create application. Maybe there are some fields problem?';
         reject(err);
         return;
       }

       //If user do not login or no permission
       if(status === 401){
         const err = 'Sorry, You don\'t had permission to access it.';
         reject(err);
         return;
       }

       const resultJson = await fetchResult.json();

       resolve(resultJson);
     });
  },

  async updateAttendanceApplication(baseApiUrl, applicationId, applicationInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'attendance',
        applicationInfo,
      };

      const apiUrl = `${baseApiUrl}/application/${applicationId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update application information. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadUserAttendanceApplications(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        user_id: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'attendance',
        search: true,
      };
      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/application?${query}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'GET',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update application information. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async deleteAttendanceApplication(baseApiUrl, applicationId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'attendance',
      };

      const apiUrl = `${baseApiUrl}/application/${applicationId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete application. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadAllLeaveApplications(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'leave',
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/application?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading application information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadLeaveApplication(baseApiUrl, applicationId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'leave',
      };

      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/application/${applicationId}?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading application information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async createLeaveApplication(baseApiUrl, applicationInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
       const postValues = {
         userId: loginInfo['userId'],
         token: loginInfo['token'],
         type: 'leave',
         applicationInfo,
       };

       const apiUrl = `${baseApiUrl}/application`;
       const fetchResult = await fetch(apiUrl, {
         method: 'POST',
         headers: {
          'Content-type': 'application/json; charset=UTF-8'
         },
         body: JSON.stringify(postValues),
       });

       const status = await fetchResult.status;

       //If cannot connect with API server
       if(status !== 200 && status !== 401){
         const err = 'Cannot create application. Maybe there are some fields problem?';
         reject(err);
         return;
       }

       //If user do not login or no permission
       if(status === 401){
         const err = 'Sorry, You don\'t had permission to access it.';
         reject(err);
         return;
       }

       const resultJson = await fetchResult.json();

       resolve(resultJson);
     });
  },

  async updateLeaveApplication(baseApiUrl, applicationId, applicationInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const putValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'leave',
        applicationInfo,
      };

      const apiUrl = `${baseApiUrl}/application/${applicationId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(putValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update application information. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadUserLeaveApplications(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryList = {
        userId: loginInfo['userId'],
        user_id: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'leave',
        search: true,
      };
      const query = queryString.stringify(queryList);

      const apiUrl = `${baseApiUrl}/application?${query}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'GET',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update application information. Maybe there are some fields or network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteLeaveApplication(baseApiUrl, applicationId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        type: 'leave',
      };

      const apiUrl = `${baseApiUrl}/application/${applicationId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete application. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadAllWorkshift(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      });
      const apiUrl = `${baseApiUrl}/workshift?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading workshift information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async loadUserWorkshift(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const query = queryString.stringify({
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        employee_id: loginInfo['userId'],
      });
      const apiUrl = `${baseApiUrl}/workshift/user?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading workshift information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async createWorkshift(baseApiUrl, workshiftInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        workshiftInfo: workshiftInfo,
      };

      const apiUrl = `${baseApiUrl}/workshift`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create workshift information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async updateWorkshift(baseApiUrl, workshiftId, workshiftInfo, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        workshiftInfo: workshiftInfo,
      };

      const apiUrl = `${baseApiUrl}/workshift/${workshiftId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot update workshift information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async deleteWorkshift(baseApiUrl, workshiftId, loginInfo){
    return new Promise(async(resolve, reject) => {
      const deleteValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };

      const apiUrl = `${baseApiUrl}/workshift/${workshiftId}`;
      const fetchResult = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(deleteValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot delete application. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
}

export default Hr;
